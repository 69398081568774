import React from 'react'
import styled from 'styled-components'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import uploadIcon from 'img/upload.svg'
import { ButtonDarkCSS } from './Buttons/ButtonDark'
import ButtonPrimary from './Buttons/ButtonPrimary'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1.2rem;
    /* max-width: 400px; */
    position: relative;
  }

  & .form-duuf-group.form-duuf-group-fileupload,
  & .form-duuf-group.form-duuf-group-submit {
    margin-bottom: 0;
  }

  .form-duuf-group.form-duuf-group-submit .form-duuf-submit > button {
    min-width: 254px;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border: none !important;
    outline: none;
    background-color: ${({ theme }) => theme.color.light};
    border-style: solid;
    border-width: 1px;
    min-height: 50px;
    padding: 0 15px;

    &::placeholder {
      opacity: 0.5;
    }
  }

  & .form-duuf-textarea {
    padding: 10px 15px !important;
    min-height: 170px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonDarkCSS};
    display: block;
    width: 100%;

    button {
      width: 100%;
      min-height: 50px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url(${uploadIcon});
        display: block;
        width: 18px;
        height: 18px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
      }
    }
  }

  & .form-duuf-file-name {
    width: 100%;
    border: none !important;
    outline: none;
    background-color: ${({ theme }) => theme.color.light};
    border-style: solid;
    border-width: 1px;
    min-height: 50px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &::before {
      content: 'Bestand:';
      margin-right: 10px;
      font-weight: normal;
    }
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: #000;
    position: absolute;
    bottom: -22px;
  }

  & .form-duuf-submit {
    ${ButtonDarkCSS};

    &-disabled {
      cursor: not-allowed;
    }
  }

  & .form-duuf-confirm {
    color: #fff;
    text-align: center;
    font-size: ${({ theme }) => theme.font.size.big};
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.color.green};
`

interface FormApplyProps {
  vacancyName: string
}

const FormApply: React.FC<FormApplyProps> = ({ vacancyName }) => (
  <section id="applyForm">
    <div className="container mt-5">
      <StyledFormDuuf
        id={2}
        generate={false}
        defaultFields={{
          input_7: vacancyName,
        }}
      >
        <div className="row justify-content-center">
          <div className="col-6">
            <FormDuufGroup showIds={[2]} />
          </div>
          <div className="col-6">
            <FormDuufGroup showIds={[3]} />
          </div>
          <div className="col-12">
            <FormDuufGroup showIds={[4, 5]} />
          </div>
          <div className="col-12">
            <FormDuufGroup showIds={[1]} />
            <p className="text-center text-white mt-1">
              Niet verplicht, mag ook later.
            </p>
          </div>
          <div className="col-12">
            <FormDuufGroup hideIds={[1, 2, 3, 4, 5]} />
          </div>
          <div className="col-12 d-flex flex-column align-items-center">
            <div className="mb-3">
              <FormDuufSubmit />
              <p className="text-center text-white mt-1">
                Binnen 1 werkdag reactie.
              </p>
            </div>
            {/* <StyledButtonPrimary
              to="https://api.whatsapp.com/send?phone=31627349050"
              target="_blank"
            >
              Stel je vraag via WhatsApp
            </StyledButtonPrimary> */}
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </section>
)

export default FormApply
